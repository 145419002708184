body {
  margin: 0;
  font-family: Helvetica;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
:root {
  --primary: #DBEEFE;
  --black: #000000;
  --white: #fff;
  --accent: #333;
  --gradiant--light: #FFFDF8;
  --gradiant--dark: #CFE8FF;
}
h1 {
  color: var(--accent);
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
}
h2{
  color: var(--accent);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;
}
h3{
  color: var(--accent);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
p{
  color: var(--accent);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card {
  height: 30rem;
  width: 20rem;
  display: inline-block;
  background-color: var(--white);
  border: 3px solid var(--accent);
  border-radius: 15px;
}
.card--content-box {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  justify-content: space-between;
}
.card--img {
  max-height: 10rem;
}
.card--text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 12rem;
}
.card--name {
  padding-top: 2rem;
  height: 4.5rem;
}
.card--button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* NAVBAR */
.navbar--resume {
  border: 2px solid var(--accent);
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  background-color: var(--white);
  color:var(--accent);
}
.navbar--resume:hover {
  color: var(--white);
  background-color: var(--accent);
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--accent);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  top: 0;
  left: 0;
  right: 0;
  background: var(--white);
  position:fixed;
  gap: 20rem;
}
.nav--items {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.navbar--button {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  background-color: transparent;
  color: var(--accent);
}
.navbar--button:hover {
  background-color: var(--gradiant--dark);
}

/* HERO */
.hero--button {
  background-color: Transparent;
  border: none;
  opacity: 1;
  cursor: pointer;
}
.hero--button:hover {
  opacity: 0.8;
}
.hero--section--changing--text {
  color: var(--accent);
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.hero--img{
  flex: 0 0 40%;
}
.hero--img > img{
  width: 22rem;
  flex-shrink: 0;
}
.hero--section {
  display: grid;
  grid-template-columns: repeat(1fr, 3);
  padding-top: 10rem;
  padding-right: 20rem;
  padding-left: 20rem;
  padding-bottom: 4rem;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  background-image: linear-gradient(var(--gradiant--light), var(--gradiant--dark));
}
.hero--content-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero--content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 0 0 60%;
}
.hero--button-box {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: flex-start;
}
.hero--downwards {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* ABOUT */
.about--section {
  padding-top: 10rem;
  padding-right: 20rem;
  padding-left: 20rem;
  padding-bottom: 4rem;
  background-color: var(--gradiant--dark);
}
.about--content {
  display: flex;
  flex-direction: row;
  gap: 7rem;
  align-items: flex-start;
  justify-content: center;
}
.about--information {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 0 0 55%;
}
.about--information-points {
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
}
.about--other {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.about--other--title {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}
.about--spotify {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  padding-left: 7rem;
}
.about--substack {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  padding-left: 2rem;
}
.substack--button {
  padding: 1rem;
  border: 2px solid var(--accent);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  background-color: var(--white);
}
.substack--button:hover {
  background-color: var(--accent);
  color: var(--white);
}

/* Portfolio */
.portfolio--section {
  padding-top: 10rem;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-bottom: 8rem;
  background-image: linear-gradient(var(--gradiant--dark), var(--gradiant--light));
}
.portfolio--container-box {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.portfolio--container--header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
}
.portfolio--card--box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.portfolio--card--box2{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* Footer */
.footer--section{
  background-color: var(--accent);
  padding: 2rem;
}
.footer--text{
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer--writing{
  color: var(--gradiant--light);
}